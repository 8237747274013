
<template>
  <div class="edit_insurance">
    <div class="title">编辑开票终端</div>
    <el-form :model="form" ref="form" label-width="11rem" class="ruleForm" :rules="rules">
      <!-- skUrl kpddm checkSpbmjc taxId taxName swjgdm swjgmc companyId userId -->
      <el-form-item label="税控服务器地址端口" prop="skUrl">
        <el-input v-model="form.skUrl" placeholder="请输入税控服务器地址端口"></el-input>
      </el-form-item>
      <el-form-item label="开票终端代码" prop="kpddm">
        <el-input v-model="form.kpddm" placeholder="请输入开票终端代码" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="开票是否校验商品编码简称" label-width="11rem" required>
          <el-radio-group v-model="form.checkSpbmjc" @change="changeGroup" >
              <el-radio label="1" value="1">是</el-radio>
              <el-radio label="0" value="0">否</el-radio>
          </el-radio-group>
      </el-form-item>
      <el-form-item label="" label-width="11rem" required>
          <el-button @click="handleVerify" type="primary" >点击验证以下信息</el-button>
      </el-form-item>
      <el-form-item label="纳税人识别号" prop="taxId">
        <el-input v-model="form.taxId"  maxlength="80" disabled></el-input>
      </el-form-item>
      <el-form-item label="纳税人名称" prop="taxName">
        <el-input v-model="form.taxName"  disabled></el-input>
      </el-form-item>
      <el-form-item label="税务机关代码" prop="swjgdm">
        <el-input v-model="form.swjgdm"  maxlength="80" disabled></el-input>
      </el-form-item>
      <el-form-item label="税务机关名称" prop="swjgmc">
        <el-input v-model="form.swjgmc"  disabled></el-input>
      </el-form-item>

      <el-form-item class="btn">
        <el-button type="primary" @click="submitForm()" :loading="isLoading">保存</el-button>
        <el-button @click="back" class="cancel_btn">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addTerminal } from '@/api/setInvoice'
export default {
  data() {
    return {
      className: "",
      // skUrl kpddm checkSpbmjc taxId taxName swjgdm swjgmc companyId userId
      form: {
        // asId: this.getToken('asId'),
        skUrl: '', //税控服务器地址端口
        kpddm: '', //开票终端代码
        checkSpbmjc:'1',//开票是否校验商品编码简称 0不校验；1校验
        taxId:'', //纳税人识别号
        taxName:'', //纳税人名称
        swjgdm:'',//税务机关代码
        swjgmc:'', //税务机关名称
      },
      rules: {
        skUrl: [
          { required: true, message: '请输入税控服务器地址端口', trigger: 'blur' },
        ],
        // taxId: [
        //   { required: true, validator: checkTax, trigger: "blur" },
        // ],
        kpddm: [{ required: true, message: '请输入税控服务器地址端口', trigger: "blur" }],
      },
      isLoading: false,

    }
  },

  methods: {
    changeGroup(){

    },
//开票终端单个查询
 init(){  //初始化内容
 		this.isLoading = true;
    let data = {companyId: this.getToken('companyId'),id:this.$route.query.id, };
    console.log(data,'datadatadatadata')
    this.$store.dispatch("findTerminalId", data).then(res => {
            this.isLoading = false;
           if (res.success) {
              this.form = res.data;
            } else {
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
              this.isLoading = false;
          })
     
      
 },
  //点击验证
  handleVerify(){
    this.$refs.form.validate((valid) => {
        if (valid) {
      this.$store.dispatch("invoiceServer", {
        companyId : this.getToken('companyId'),
        kpzdbs: this.form.kpddm,
        skUrl:this.form.skUrl,
        fplxdm:'',
      }).then(res => {
            if (res.success) {
              this.form.taxId = res.data.nsrsbh;
              this.form.taxName = res.data.nsrmc;
              this.form.swjgdm = res.data.swjgdm;
              this.form.swjgmc = res.data.swjgmc;
            } else {
              this.$message.error(res.msg)
            }
          }).catch(err => {
            this.isLoading = false
          })
        }
    })
  },
  
 // 保存
    submitForm() {
      // companyId
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {...this.form, companyId: this.getToken('companyId'), userId: this.getToken('userId'), id:this.$route.query.id, };
          this.$store.dispatch("editTerminal", data).then(res => {
            this.isLoading = true;
            if (res.success) {
              this.isLoading = false;
              this.$router.push('/install/invoicingTerminal')
            } else {
              this.$message.error(res.msg)
              this.isLoading = false
            }
          }).catch(err => {
            this.isLoading = false
          })
        }
      });
    },
    back() {
      this.$router.push('/install/invoicingTerminal');
    },

   
  },

  mounted() {
    // this.accountCapitalName();
    this.init();
  }

}
</script>
<style lang="less" scoped>
.edit_insurance {
  height: calc(100% - 60px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    text-align: center;
    font-weight: 700;
    color: #333333;
    font-size: 18px;
    padding: 18px 0;
    width: calc(100% - 40px);
    border-bottom: 1px solid #dcdcdc;
  }
  .ruleForm {
    margin: 40px auto;
    .el-form-item__content {
      .el-input__inner {
        width: 420px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      .el-input.is-disabled {
        .el-input__inner {
          background-color: #f6f6f6;
        }
      }
    }
    .el-input{
      width: 350px !important;
    }
    

    .btn {
      text-align: center;
      .el-button {
        width: 100px;
      }
      .cancel_btn {
        margin-left: 20px;
      }
    }
  }
}
.el-form-item__content {
  text-align: center !important;
}

</style>